var constants = {
  LANGUAGES: ["es", "en", "eu", "de", "fr", "ca"],
  // SERVER_URL: "https://ederback.informagedevelop.com/api/",
  SERVER_URL: "https://back.turismoriberanavarra.com/api/",
  COOKIES_STORAGE_KEY: 'ACEPTO_COOKIES_TURISMO_RIVERA',
  WEBSITE_URL:'https://turismoriberanavarra.com',
  SITEMAP_FILE_NAME: 'public/sitemap.xml'
}

module.exports =  constants
