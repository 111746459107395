import { createAction, handleActions } from "redux-actions"
import { getSectionDetailByTitle } from "../../services/http"

const initialState = {
  errors: [],
  isLoading: true,
  data: {},
}

const FETCH_ARTICLE = "FETCH_ARTICLE"
const FETCH_ARTICLE_FULFILLED = "FETCH_ARTICLE_FULFILLED"
const FETCH_ARTICLE_PENDING = "FETCH_ARTICLE_PENDING"
const FETCH_ARTICLE_FAILED = "FETCH_ARTICLE_FAILED"

export default handleActions(
  {
    [FETCH_ARTICLE_PENDING]: state => {
      return { ...state, isLoading: true, errors: [] }
    },
    [FETCH_ARTICLE_FAILED]: (state, action) => {
      return { ...state, isLoading: false, errors: [...action.payload] }
    },
    [FETCH_ARTICLE_FULFILLED]: (state, action) => {
      const { data } = action.payload
      return { ...state, isLoading: false, data: { ...data } }
    },
  },
  initialState
)

export const fetchArticleData = createAction(FETCH_ARTICLE, (title, lang) =>
  getSectionDetailByTitle(title, lang)
)
