import $ from "jquery"
import "swiper/css/swiper.min.css"
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/js/bootstrap.bundle.min"
import "mapbox-gl/dist/mapbox-gl.css"
import "./src/styles/main.scss"
import wrapWithProvider from "./wrap-with-provider"
import sprite from "./src/images/sprite.svg"

export const wrapRootElement = wrapWithProvider
// load social icons sprite through ajax
$.get(sprite, function (data) {
  const div = document.createElement("div")
  div.innerHTML = new XMLSerializer().serializeToString(data.documentElement)
  document.body.insertBefore(div, document.body.childNodes[0])
})
