import { createAction, handleActions } from "redux-actions"
import { getSectionByTitle, getSubSectionByTitle } from "../../services/http"

const initialState = {
  errors: [],
  isLoading: true,
  data: {},
}

const FETCH_SECTION = "FETCH_SECTION"
const FETCH_SECTION_FULFILLED = "FETCH_SECTION_FULFILLED"
const FETCH_SECTION_PENDING = "FETCH_SECTION_PENDING"
const FETCH_SECTION_FAILED = "FETCH_SECTION_FAILED"

export default handleActions(
  {
    [FETCH_SECTION_PENDING]: state => {
      return { ...state, errors: [], isLoading: true }
    },
    [FETCH_SECTION_FAILED]: (state, action) => {
      return { ...state, isLoading: false, errors: [...action.payload] }
    },
    [FETCH_SECTION_FULFILLED]: (state, action) => {
      const { data } = action.payload
      return {
        ...state,
        isLoading: false,
        data: { ...data },
      }
    },
  },
  initialState
)

export const fetchSection = createAction(FETCH_SECTION, (title, lang) =>
  getSectionByTitle(title, lang)
)
export const fetchSubsection = createAction(FETCH_SECTION, (title, lang) =>
  getSubSectionByTitle(title, lang)
)
