import { createAction, handleActions } from "redux-actions"
import { getCookiesPolicyContent } from "../../services/http"

const initialState = {
  errors: [],
  isLoading: true,
  data: [],
  componentVisible:false
}

const FETCH_COOKIES_POLICY = "FETCH_COOKIES_POLICY"
const FETCH_COOKIES_POLICY_FULFILLED = "FETCH_COOKIES_POLICY_FULFILLED"
const FETCH_COOKIES_POLICY_PENDING = "FETCH_COOKIES_POLICY_PENDING"
const FETCH_COOKIES_POLICY_FAILED = "FETCH_COOKIES_POLICY_FAILED"
const COOKIES_COMPONENT_VISIBLE = "COOKIES_COMPONENT_VISIBLE"

export default handleActions(
  {
    [FETCH_COOKIES_POLICY_PENDING]: state => {
      return { ...state, isLoading: true,errors:[] }
    },
    [FETCH_COOKIES_POLICY_FAILED]: (state, action) => {
      return { ...state, isLoading: false, errors: [...action.payload] }
    },
    [FETCH_COOKIES_POLICY_FULFILLED]: (state, action) => {
      const { data } = action.payload
      return {
        ...state,
        isLoading: false,
        data: { ...data[0] },
      }
    },
    [COOKIES_COMPONENT_VISIBLE]: (state, action) => {
      return { ...state, componentVisible:action.payload }
    },
  },
  initialState
)

export const fetchCookiesPolicy = createAction(FETCH_COOKIES_POLICY, (lang='es')=>getCookiesPolicyContent(lang))
export const updateCookiesComponentVisibility = (payload=true)=>({type:COOKIES_COMPONENT_VISIBLE,payload})
