import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit"

import rootReducer from "./actions"
import promise from "redux-promise-middleware"

export default preloadedState => {
  const myMiddlewares = [promise]
  const store = configureStore({
    reducer: rootReducer,
    middleware: [...myMiddlewares, ...getDefaultMiddleware()],
    preloadedState,
  })

  if (process.env.NODE_ENV !== "production" && module.hot) {
    module.hot.accept("./actions", () => store.replaceReducer(rootReducer))
  }

  return store
}
