import { createAction, handleActions } from "redux-actions"
import { getHomeContent } from "../../services/http"

const initialState = {
  errors: [],
  isLoading: true,
  data: null,
}

const FETCH_HOME = "FETCH_HOME"
const FETCH_HOME_FULFILLED = "FETCH_HOME_FULFILLED"
const FETCH_HOME_PENDING = "FETCH_HOME_PENDING"
const FETCH_HOME_FAILED = "FETCH_HOME_FAILED"

export default handleActions(
  {
    [FETCH_HOME_PENDING]: state => {
      return { ...state, errors: [], isLoading: true }
    },
    [FETCH_HOME_FAILED]: (state, action) => {
      return { ...state, isLoading: false, errors: [...action.payload] }
    },
    [FETCH_HOME_FULFILLED]: (state, action) => {
      const { data } = action.payload
      return {
        ...state,
        isLoading: false,
        data: { ...data[0] },
      }
    },
  },
  initialState
)

export const fetchHomeData = createAction(FETCH_HOME, () => getHomeContent())
