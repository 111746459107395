import { createAction, handleActions } from "redux-actions"
import { getTowns } from "../../services/http"

const initialState = {
  data: {},
  isLoading: true,
  errors: [],
}

const FETCH_TOWNS = "FETCH_TOWNS"
const FETCH_TOWNS_FULFILLED = "FETCH_TOWNS_FULFILLED"
const FETCH_TOWNS_PENDING = "FETCH_TOWNS_PENDING"
const FETCH_TOWNS_FAILED = "FETCH_TOWNS_FAILED"

export default handleActions(
  {
    [FETCH_TOWNS_PENDING]: state => {
      return { ...state, isLoading: true, errors: [] }
    },
    [FETCH_TOWNS_FAILED]: (state, action) => {
      return { ...state, isLoading: false, errors: [...action.payload] }
    },
    [FETCH_TOWNS_FULFILLED]: (state, action) => {
      const { data } = action.payload
      return { ...state, isLoading: false, data: { ...data } }
    },
  },
  initialState
)

export const fetchTownsData = createAction(FETCH_TOWNS, (idSubSection) =>
    getTowns(idSubSection)
)