import { createAction, handleActions } from "redux-actions"
import { getGeoData } from "../../services/http"

const initialState = {
  errors: [],
  isLoading: false,
  data: [],
}

const FETCH_GEOLOCATION = "FETCH_GEOLOCATION"
const FETCH_GEOLOCATION_FULFILLED = "FETCH_GEOLOCATION_FULFILLED"
const FETCH_GEOLOCATION_PENDING = "FETCH_GEOLOCATION_PENDING"
const FETCH_GEOLOCATION_FAILED = "FETCH_GEOLOCATION_FAILED"

export default handleActions(
  {
    [FETCH_GEOLOCATION_PENDING]: state => {
      return { ...state, errors: [], isLoading: true }
    },
    [FETCH_GEOLOCATION_FAILED]: (state, action) => {
      return { ...state, isLoading: false, errors: [...action.payload] }
    },
    [FETCH_GEOLOCATION_FULFILLED]: (state, action) => {
      const { data } = action.payload
      return {
        ...state,
        isLoading: false,
        data: [...data],
      }
    },
  },
  initialState
)

export const fetchGeoData = createAction(FETCH_GEOLOCATION, lang =>
  getGeoData(lang)
)
