var axios = require("axios")
var constants = require("../../constants")

function get(endpoint) {
  return axios.get(`${constants.SERVER_URL}${endpoint}`)
}

function getSeoData(lang = "es") {
  return get(`seo?lang=${lang}`)
}

function getLegalContent(lang = "es") {
  return get(`legal?lang=${lang}`)
}

function getHomeContent() {
  return get(`home`)
}

function getHomeSections(lang = "es") {
  return get(`section?activeHome=true&lang=${lang}`)
}

function getFooterContent() {
  return get(`footer`)
}

function getGeoData(lang = "es") {
  return get(`section/home/geolocation-details?lang=${lang}`)
}

function getSectionByTitle(title, lang = "es") {
  return get(`section/${title}?lang=${lang}`)
}

function getSubSectionByTitle(title, lang = "es") {
  return get(`section/subsection/${title}?lang=${lang}`)
}

function getSubSectionsBySectionTitle(title, lang = "es") {
  return get(`section/${title}/subsections?lang=${lang}`)
}

function getSubSectionsBySubSectionTitle(title, lang = "es", town = "") {
  return get(`section/subsection/${title}/details?lang=${lang}&town=${town}`)
}

function getSectionDetailByTitle(title, lang = "es") {
  return get(`section/detail/${title}?lang=${lang}`)
}

function searchByTitle(title, lang = "es") {
  return get(`section/general/search?search=${title}&lang=${lang}`)
}

function getNavContent(lang) {
  return get(`nav?lang=${lang}`)
}

function getAvailableLangs() {
  return get(`lang`)
}

function getCookiesPolicyContent(lang) {
  return get(`cookies-policy?lang=${lang}`)
}

function getSitemapContent() {
  return get(`sitemap`)
}

function getTowns(idSubSection) {
  return get(`section/subsection/${idSubSection}/towns`)
}

function getDocuments() {
  return get(`document`)
}

function getDocumentSection(){
  return get(`document/config`)
}

module.exports = {
  getSeoData,
  getLegalContent,
  getHomeContent,
  getHomeSections,
  getFooterContent,
  getGeoData,
  getSectionByTitle,
  getSubSectionByTitle,
  getSubSectionsBySectionTitle,
  getSubSectionsBySubSectionTitle,
  getSectionDetailByTitle,
  searchByTitle,
  getNavContent,
  getAvailableLangs,
  getCookiesPolicyContent,
  getSitemapContent,
  getTowns,
  getDocuments,
  getDocumentSection
}
