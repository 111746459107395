import { createAction, handleActions } from "redux-actions"
import { getDocumentSection } from "../../services/http"

const initialState = {
  errors: [],
  isLoading: true,
  data: {},
}

const FETCH_SECTION_DOCUMENT = "FETCH_SECTION_DOCUMENT"
const FETCH_SECTION_DOCUMENT_FULFILLED = "FETCH_SECTION_DOCUMENT_FULFILLED"
const FETCH_SECTION_DOCUMENT_PENDING = "FETCH_SECTION_DOCUMENT_PENDING"
const FETCH_SECTION_DOCUMENT_FAILED = "FETCH_SECTION_DOCUMENT_FAILED"

export default handleActions(
  {
    [FETCH_SECTION_DOCUMENT_PENDING]: state => {
      return { ...state, isLoading: true, errors: [] }
    },
    [FETCH_SECTION_DOCUMENT_FAILED]: (state, action) => {
      return { ...state, isLoading: false, errors: [...action.payload] }
    },
    [FETCH_SECTION_DOCUMENT_FULFILLED]: (state, action) => {
      const { data } = action.payload
      return { ...state, isLoading: false, data: { ...data } }
    },
  },
  initialState
)

export const fetchSectionDocumentsData = createAction(FETCH_SECTION_DOCUMENT, () =>
    getDocumentSection()
)