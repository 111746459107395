import { createAction, handleActions } from "redux-actions"
import { getSeoData } from "../../services/http"

const initialState = {
  errors: [],
  isLoading: true,
  data: {
    title: "",
    keywords:
      "",
    description:
      "",
  },
}

const FETCH_SEO = "FETCH_SEO"
const FETCH_SEO_FULFILLED = "FETCH_SEO_FULFILLED"
const FETCH_SEO_PENDING = "FETCH_SEO_PENDING"
const FETCH_SEO_FAILED = "FETCH_SEO_FAILED"

export default handleActions(
  {
    [FETCH_SEO_PENDING]: state => {
      return { ...state, errors: [], isLoading: true }
    },
    [FETCH_SEO_FAILED]: (state, action) => {
      return { ...state, isLoading: false, errors: [...action.payload] }
    },
    [FETCH_SEO_FULFILLED]: (state, action) => {
      const { data } = action.payload
      return { ...state, isLoading: false, data: data }
    },
  },
  initialState
)

export const fetchSeoData = createAction(FETCH_SEO, (lang = "es") =>
  getSeoData(lang)
)
