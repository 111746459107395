import { createAction, handleActions } from "redux-actions"
import { getDocuments } from "../../services/http"

const initialState = {
  errors: [],
  isLoading: true,
  data: {},
}

const FETCH_DOCUMENT = "FETCH_DOCUMENT"
const FETCH_DOCUMENT_FULFILLED = "FETCH_DOCUMENT_FULFILLED"
const FETCH_DOCUMENT_PENDING = "FETCH_DOCUMENT_PENDING"
const FETCH_DOCUMENT_FAILED = "FETCH_DOCUMENT_FAILED"

export default handleActions(
  {
    [FETCH_DOCUMENT_PENDING]: state => {
      return { ...state, isLoading: true, errors: [] }
    },
    [FETCH_DOCUMENT_FAILED]: (state, action) => {
      return { ...state, isLoading: false, errors: [...action.payload] }
    },
    [FETCH_DOCUMENT_FULFILLED]: (state, action) => {
      const { data } = action.payload
      return { ...state, isLoading: false, data: { ...data } }
    },
  },
  initialState
)

export const fetchDocumentsData = createAction(FETCH_DOCUMENT, () =>
    getDocuments()
)