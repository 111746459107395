import { createAction, handleActions } from "redux-actions"
import {
  getSubSectionsBySectionTitle,
  getSubSectionsBySubSectionTitle,
} from "../../services/http"

const initialState = {
  errors: [],
  isLoading: true,
  data: [],
}

const FETCH_SUBSECTIONS = "FETCH_SUBSECTIONS"
const FETCH_SUBSECTIONS_FULFILLED = "FETCH_SUBSECTIONS_FULFILLED"
const FETCH_SUBSECTIONS_PENDING = "FETCH_SUBSECTIONS_PENDING"
const FETCH_SUBSECTIONS_FAILED = "FETCH_SUBSECTIONS_FAILED"

export default handleActions(
  {
    [FETCH_SUBSECTIONS_PENDING]: state => {
      return { ...state, errors: [], isLoading: true }
    },
    [FETCH_SUBSECTIONS_FAILED]: (state, action) => {
      return { ...state, isLoading: false, errors: [...action.payload] }
    },
    [FETCH_SUBSECTIONS_FULFILLED]: (state, action) => {
      const { data } = action.payload
      data.sort((a, b) => a.orderPosition - b.orderPosition)
      return { ...state, isLoading: false, data: [...data] }
    },
  },
  initialState
)

export const fetchSubSections = createAction(FETCH_SUBSECTIONS, (title, lang) =>
  getSubSectionsBySectionTitle(title, lang)
)
export const fetchSubSectionChildren = createAction(
  FETCH_SUBSECTIONS,
  (title, lang, town) => getSubSectionsBySubSectionTitle(title, lang, town)
)
