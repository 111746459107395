import { createAction, handleActions } from "redux-actions"
import { getAvailableLangs } from "../../services/http"

const initialState = {
  errors: [],
  isLoading: true,
  data: [{code:'es', name:'Español'}],
}

const FETCH_LANGS = "FETCH_LANGS"
const FETCH_LANGS_FULFILLED = "FETCH_LANGS_FULFILLED"
const FETCH_LANGS_PENDING = "FETCH_LANGS_PENDING"
const FETCH_LANGS_FAILED = "FETCH_LANGS_FAILED"

export default handleActions(
  {
    [FETCH_LANGS_PENDING]: state => {
      return { ...state, isLoading: true }
    },
    [FETCH_LANGS_FAILED]: (state, action) => {
      return { ...state, isLoading: false, errors: [...action.payload] }
    },
    [FETCH_LANGS_FULFILLED]: (state, action) => {
      const { data } = action.payload
      return { ...state, isLoading: false, data }
    },
  },
  initialState
)

export const fetchAvailableLangs = createAction(FETCH_LANGS, getAvailableLangs)
