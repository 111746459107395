import { createAction, handleActions } from "redux-actions"
import { searchByTitle } from "../../services/http"

const initialState = {
  errors: [],
  isLoading: true,
  data: [],
}

const GENERAL_SEARCH_CLEAN_UP = "GENERAL_SEARCH_CLEAN_UP"
const GENERAL_SEARCH = "GENERAL_SEARCH"
const GENERAL_SEARCH_FULFILLED = "GENERAL_SEARCH_FULFILLED"
const GENERAL_SEARCH_PENDING = "GENERAL_SEARCH_PENDING"
const GENERAL_SEARCH_FAILED = "GENERAL_SEARCH_FAILED"

export default handleActions(
  {
    [GENERAL_SEARCH_PENDING]: state => {
      return { ...state, errors: [], isLoading: true }
    },
    [GENERAL_SEARCH_FAILED]: (state, action) => {
      return { ...state, isLoading: false, errors: [...action.payload] }
    },
    [GENERAL_SEARCH_FULFILLED]: (state, action) => {
      const { data } = action.payload
      return { ...state, isLoading: false, data: [...data] }
    },
    [GENERAL_SEARCH_CLEAN_UP]: (state) => {
      return { ...state, isLoading: false, data: [] }
    },
  },
  initialState
)

export const generalSearch = createAction(GENERAL_SEARCH, (title, lang) =>

  searchByTitle(title, lang)
)
export const generalSearchCleanUp = ( )=>({type:GENERAL_SEARCH_CLEAN_UP})
