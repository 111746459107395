import { createAction, handleActions } from "redux-actions"

const initialState = {
  open: false,
}
const TOGGLE_SIDEBAR_VISIBILITY = "TOGGLE_SIDEBAR_VISIBILITY"

export default handleActions(
  {
    [TOGGLE_SIDEBAR_VISIBILITY]: state => {
      const { open } = state
      return { ...state, open: !open }
    },
  },
  initialState
)

export const toggleSidebarVisibility = createAction(TOGGLE_SIDEBAR_VISIBILITY)
