import { createAction, handleActions } from "redux-actions"
import { getFooterContent } from "../../services/http"

const initialState = {
  errors: [],
  isLoading: true,
  data: {},
}

const FETCH_FOOTER = "FETCH_FOOTER"
const FETCH_FOOTER_FULFILLED = "FETCH_FOOTER_FULFILLED"
const FETCH_FOOTER_PENDING = "FETCH_FOOTER_PENDING"
const FETCH_FOOTER_FAILED = "FETCH_FOOTER_FAILED"

export default handleActions(
  {
    [FETCH_FOOTER_PENDING]: state => {
      return { ...state, errors: [], isLoading: true }
    },
    [FETCH_FOOTER_FAILED]: (state, action) => {
      return { ...state, isLoading: false, errors: [...action.payload] }
    },
    [FETCH_FOOTER_FULFILLED]: (state, action) => {
      const { data } = action.payload
      return {
        ...state,
        isLoading: false,
        data: { ...data[0] },
      }
    },
  },
  initialState
)

export const fetchFooterData = createAction(FETCH_FOOTER, () =>
  getFooterContent()
)
