import { createAction, handleActions } from "redux-actions"
import { getCookiesPolicyContent, getNavContent } from "../../services/http"

const initialState = {
  errors: [],
  isLoading: true,
  detailsView: false,
  data: {
    menuBackgroundImage: { id: "" },
    navBackgroundColor: "white",
    navLogo: { id: "" },
    navDetailLogo: { id: "" },
    menuLogo: { id: "" },
    menuBackgroundTypeImage: true,
    menuColorButtons: "white",
    navBackgroundTypeTransparent: false,
    navColorButtons: "white",
    navDetailBackgroundColor: "white",
    navDetailColorButtons: "#AE1065",
  },
}

const FETCH_NAV = "FETCH_NAV"
const FETCH_NAV_FULFILLED = "FETCH_NAV_FULFILLED"
const FETCH_NAV_PENDING = "FETCH_NAV_PENDING"
const FETCH_NAV_FAILED = "FETCH_NAV_FAILED"
const SET_DETAILS_VIEW = "SET_DETAILS_VIEW"

export default handleActions(
  {
    [FETCH_NAV_PENDING]: state => {
      return { ...state, isLoading: true }
    },
    [FETCH_NAV_FAILED]: (state, action) => {
      return { ...state, isLoading: false, errors: [...action.payload] }
    },
    [FETCH_NAV_FULFILLED]: (state, action) => {
      const { data } = action.payload
      return { ...state, isLoading: false, data: { ...data[0] } }
    },
    [SET_DETAILS_VIEW]: (state, action) => {
      return { ...state, detailsView: action.payload }
    },
  },
  initialState
)

export const fetchNavData = createAction(FETCH_NAV, (lang='es')=>getNavContent(lang))
export const setNavDetailsView = createAction(SET_DETAILS_VIEW)
