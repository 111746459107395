import { createAction, handleActions } from "redux-actions"
import { getHomeSections } from "../../services/http"

const initialState = {
  errors: [],
  isLoading: true,
  data: [],
}

const FETCH_HOME_SECTIONS = "FETCH_HOME_SECTIONS"
const FETCH_HOME_SECTIONS_FULFILLED = "FETCH_HOME_SECTIONS_FULFILLED"
const FETCH_HOME_SECTIONS_PENDING = "FETCH_HOME_SECTIONS_PENDING"
const FETCH_HOME_SECTIONS_FAILED = "FETCH_HOME_SECTIONS_FAILED"

export default handleActions(
  {
    [FETCH_HOME_SECTIONS_PENDING]: state => {
      return { ...state, errors: [], isLoading: true }
    },
    [FETCH_HOME_SECTIONS_FAILED]: (state, action) => {
      return { ...state, isLoading: false, errors: [...action.payload] }
    },
    [FETCH_HOME_SECTIONS_FULFILLED]: (state, action) => {
      const { data } = action.payload
      data.sort((a, b) => a.orderPosition - b.orderPosition)
      return {
        ...state,
        isLoading: false,
        data,
      }
    },
  },
  initialState
)

export const fetchHomeSections = createAction(FETCH_HOME_SECTIONS, lang =>
  getHomeSections(lang)
)
