import { combineReducers } from "redux"
// all reducers
import sidebarReducer from "./sidebar"
import homeSectionsReducer from "./home-sections"
import sectionReducer from "./section"
import subSectionReducer, { fetchSubSections } from "./sub-sections"
import seoReducer from "./seo"
import footerReducer from "./footer"
import homeReducer from "./home"
import legalReducer from "./legal"
import navReducer from "./nav"
import articleReducer from "./article"
import geolocationReducer from "./geolocation"
import searchReducer from "./search"
import availableLangs from "./lang"
import cookiesPolicy from "./cookies-policy"
import townsReducer from "./towns"
import fetchDocumentsData from "./documents"
import fetchSectionDocumentsData from './section-document'

export const rootReducer = combineReducers({
  article: articleReducer,
  towns: townsReducer,
  footer: footerReducer,
  home: homeReducer,
  homeSections: homeSectionsReducer,
  legal: legalReducer,
  nav: navReducer,
  section: sectionReducer,
  seo: seoReducer,
  subSections: subSectionReducer,
  sidebar: sidebarReducer,
  geolocation: geolocationReducer,
  search: searchReducer,
  langs:availableLangs,
  sectionDocument: fetchSectionDocumentsData,
  documents: fetchDocumentsData,
  cookiesPolicy
})

export default rootReducer
